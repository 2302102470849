<template>
  <LayoutFrame>
    <template #header>
      <Header />
    </template>
    <template #content>
      <SuspenseSlotWrapper>
        <Suspense>
          <ClientOnly>
            <Toast />
            <slot />
            <template #fallback>
              <div style="height: 75vh" class="relative p-0 m-0 z-[509]">
                <LoaderPageContent show-instantly />
              </div>
            </template>
          </ClientOnly>
          <template #fallback>
            <div style="height: 75vh" class="relative p-0 m-0 z-[509]">
              <LoaderPageContent />
            </div>
          </template>
        </Suspense>
      </SuspenseSlotWrapper>
    </template>
    <template #footer>
      <Benefits class="mt-xl touch:md:mt-0 lg:mt-0" />
      <Footer />
    </template>
  </LayoutFrame>
</template>
<script setup lang="ts">
import LayoutFrame from '@/components/layout/LayoutFrame.vue';
import Header from '@/components/layout/returnsCheckoutSubView/header/minilu/header.vue';
import Footer from '@/components/layout/returnsCheckoutSubView/footer/minilu/footer.vue';
import Benefits from '~/components/layout/returnsCheckoutSubView/footer/minilu/benefits.vue';
import SuspenseSlotWrapper from '~~/src/components/suspenseSlotWrapper.vue';
import LoaderPageContent from '~~/src/components/loader/pageContent/minilu/page-content.vue';
import Toast from '~~/src/components/toast/toast.vue';
import { useAreaStore } from '@/stores/useArea';
import { useSessionStore } from '@/stores/useSessionStore';
import { useReturnCheckout } from '~/stores/useReturnCheckout';

useAreaStore().setDefaultArea();

onMounted(async () => {
  if (!useSessionStore().isLoggedIn) {
    await navigateTo('/shop');
  }
});

//Only reset store here if user didn't finish the return process
onUnmounted(() => {
  if (!useReturnCheckout().returnData?.returnCreationData?.returnId) {
    useReturnCheckout().$reset();
  }
});
</script>
